<template>
    <div class="card-stack">
        <users-modal-invite-users
            :id="modalId"
            hide-organisation-select
            hide-role-select
            :organisation="group"
            :role="role"
            @invitations-created="invitationsCreated"
        />

        <b-card>
            <div class="d-flex justify-content-between align-items-center pb-3">
                <b-card-title
                    title-tag="h2"
                    class="mb-0"
                >
                    <slot
                        name="title"
                        :users="members"
                    />
                </b-card-title>

                <div v-if="showAddButton">
                    <b-button
                        v-b-modal="modalId"
                        variant="dark"
                        :disabled="canAddUser"
                    >
                        +
                    </b-button>
                </div>
            </div>

            <div
                v-if="search"
                class="d-flex pb-3"
            >
                <input-search
                    v-model="members.searchQuery"
                    class="w-100"
                />
            </div>

            <!-- TODO: Do not add actions when only invitations -->
            <members-table
                :collection="members"
                :include-fields="['name', 'createdAt', 'status', 'actions']"
                :show-email="showEmail"
                :show-since="showSince"
            />
        </b-card>
    </div>
</template>

<script>
import InputSearch from '@/components/common/form/InputSearch';
import MembersTable from '@/components/members/Table';
import UsersModalInviteUsers from '@/components/users/ModalInviteUsers';
import Role from '@/library/enumerations/Role';
import {Group} from '@/models/vue-mc/Group';
import {Members} from '@/models/Member';

export default {
    name: 'BaseMembers',
    components: {InputSearch, MembersTable, UsersModalInviteUsers},
    props: {
        role: {
            type: Number,
            default: undefined,
        },
        group: {
            type: Group,
            required: true,
        },
        search: {
            type: Boolean,
            default: false,
        },
        showEmail: {
            type: Boolean,
            default: false,
        },
        showSince: {
            type: Boolean,
            default: false,
        },
        addDisabled: {
            type: [Boolean, Function],
            default: false,
        },
        showAddButton: {
            type: Boolean,
            default: true,
        },
    },
    data: function() {
        return {
            modalId: `modal-invite-group-users-${this._uid}`,
            members: new Members([], {
                routePrefix: this.group.getFetchURL(),
            }, {
                role: this.role,
            }),
        };
    },
    computed: {
        canAddUser() {
            if (typeof this.addDisabled === 'function') {
                return this.addDisabled(this.members);
            }

            return this.addDisabled;
        },
    },
    watch: {
        async 'members.query'() {
            await this.load();
        },
    },
    async mounted() {
        await this.load();
    },
    methods: {
        invitationsCreated() {
            this.members.clear();

            this.members.page(0).fetch();
        },
        async load() {
            await this.members.page(0).fetch();
        },
        async loadNext() {
            if (!this.members.loading) {
                await this.members.fetch();
            }
        },
    },
};
</script>
