<template>
    <base-members
        :group="group"
        :role="role"
        :add-disabled="canAddAdmin"
        show-email
    >
        <template #title="{users}">
            {{ $t('groups.admins.title', getAdminCount(users)) }}
        </template>
    </base-members>
</template>

<script>
import BaseMembers from '@/pages/groups/BaseMembers';
import {Group} from '@/models/vue-mc/Group';
import Role from '@/library/enumerations/Role';

export default {
    name: 'GroupAdmins',
    components: {BaseMembers},
    props: {
        group: {
            type: Group,
            required: true,
        },
    },
    data: () => ({
        role: Role.ADMIN,
    }),
    methods: {
        canAddAdmin(collection) {
            const count = this.getAdminCount(collection);
            return count.amount >= count.total;
        },
        getAdminCount(collection) {
            if (collection.loading && collection.pagination.total <= 0) {
                return {amount: 0, total: 0};
            }
            const amount = collection.pagination.total;
            const max = 3;
            return {amount, total: Math.max(amount, max)};
        },
    },
};
</script>
